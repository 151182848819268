(async function(){
  const domainToRedirect = 'https://mcdonalds.pl/mcdelivery/';
  const mainTitle = document.querySelector('h1');
  const mainContainer = document.querySelector('#main-container');
  const textSpeed = 50;

  const typeWriter = (cont, text) => {
    return new Promise(resolve => {
      let i = 0;
      const startTyping = () => {
        if (i < text.length) {
          cont.innerHTML += text.charAt(i);
          i++;
          setTimeout(startTyping, textSpeed);
        } else {
          resolve();
        }
      }

      startTyping();
    })
  }

  const modalWillTest = () => {
    const modal = document.createElement('div');
    modal.classList.add('modal');

    modal.innerHTML = `
      <div class="modal-inner">
        <h2>🚨 Uwaga test silnej woli 🚨</h2>
        <button class="btn" data-go-test>Sprawdź się</button>
      </div>
    `;

    mainContainer.appendChild(modal);

    document.querySelector('[data-go-test]').addEventListener('click', () => {
        window.location.href = domainToRedirect;
    })

  }

  await typeWriter(mainTitle, 'Są tak naprawdę tylko dwie metody, żeby schudnąć:');

  const listEl = document.createElement('ol');
  const liEl1 = document.createElement('li');
  const liEl2 = document.createElement('li');
  listEl.appendChild(liEl1);

  mainContainer.appendChild(listEl)

  await typeWriter(liEl1, 'Mniej żreć!');

  listEl.appendChild(liEl2);

  await typeWriter(liEl2, 'Więcej się ruszać!');

  setTimeout(() => {
    modalWillTest();
  }, 500)


})();